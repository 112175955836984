import React from 'react';
import Helmet from 'react-helmet'
import { useLocation } from '@reach/router';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import favicon from '../images/favicon.ico';


// const twitterUsername = '@muscarian_games';
const defaultDescription = 'Indie developer specializing in web-based games and oddities.';
const defaultImage = 'mushroom_towers.jpeg'
const baseUrl = 'https://www.muscarian.com';

const SEO = ({
  articleData = {},
  description = defaultDescription,
  image = defaultImage,
  imageAlt = 'Mushroom Cityscape',
  imageHeight = 720,
  imageWidth = 1280,
  title,
  type,
}) => {
  const { pathname = '' } = useLocation();
  const {
    authors = ['muscarian'],
    tags = ['game development'],
    publishedDate,
  } = articleData;
  const url = pathname && pathname !== '/' ? `${baseUrl}/${pathname}` : baseUrl;
  const imageUrl = `${baseUrl}/${image}`;
  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <GatsbySeo 
        title={title}
        description={description}
        canonical={url}
        openGraph={{
          url,
          title,
          description,
          type,
          article: {
            authors,
            tags,
            publishedTime: publishedDate,
          },
          images: [{
            url: imageUrl,
            width: imageWidth,
            height: imageHeight,
            alt: imageAlt,
          }],
          site_name: 'Muscarian Softworks'
        }}
      />
    </>
  );
}

export default SEO;
