export const centeredWindowListItem = {
  display: 'list-item',
  marginRight: 'auto',
  marginLeft: 'auto',
  minWidth: '120px',
  position: 'static',
  maxWidth: '960px',
  listStyle: 'none',
};

export const iconStyle = {
  height: '1.5em',
  width: '1.5em',
};