import { useEffect } from "react";
import {
  useStyledDarkMode,
} from "gatsby-styled-components-dark-mode";

const isBrowser = typeof window !== "undefined"

const varsMap = {
  dark: {
    '--accent-color': '#07ff00',
    '--bg-color': 'rgb(24, 24, 24)',
    '--fg-color': '#fef4db',
    '--nav-bar-color': 'rgb(24, 28, 24)',
    '--menu-button-color': 'rgba(0, 0, 0, 0.2)',
  },
  light: {
    '--accent-color': '#8e0284',
    '--bg-color': 'rgb(197, 197, 197)',
    '--fg-color': '#27241e',
    '--nav-bar-color': 'rgb(180, 180, 180)',
    '--menu-button-color': 'rgba(222, 222, 222, 0.67)',
  },
};
export const useSetCssVars = () => {
  const { isDark } = useStyledDarkMode();
  useEffect(() => {
    if (!isBrowser) return;
    const root = document?.documentElement;
    if (!root) return;

    const theme = isDark === undefined || isDark === true ? 'dark' : 'light';
    const vars = varsMap[theme];
    Object.keys(vars).forEach(key => {
      root.style.setProperty(key, vars[key]);
    });
  }, [isDark]);
}
