import React from 'react';
import Helmet from 'react-helmet'

import { useLocation } from '@reach/router';

const defaultDescription = 'Indie games developer specializing in web-based oddities.';

/**
 * Schema dot org metadata for a page
 */
const Schema = ({ description = defaultDescription, moreSchema = [] }) => {
  const { href: url = 'https://www.muscarian.com' } = useLocation();
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "award": "Second place 2018 MUD jam",
              "url": "${url}",
              "name": "Muscarian Games",
              "description": "${description}",
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "Customer Support",
                "email": "admin@myelin.space"
              }
            }
          `}
      </script>
      {moreSchema.map(schema => {
        return (
          <script type="application/ld+json">
            {JSON.stringify(schema)}
          </script>
        );
      })}
    </Helmet>
  );
}

export default Schema;
