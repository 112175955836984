import React, { useState } from "react"
import { AppBar, Button, Divider, List, ListItem, Select, Toolbar } from "react95";
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import {
  useStyledDarkMode,
  ThemeSetting,
} from "gatsby-styled-components-dark-mode";
import { StaticImage } from "gatsby-plugin-image"
import { useLocation } from '@reach/router';

import { iconStyle } from "../styles";

const menuLinks = [
  {
    name: 'Blog',
    link: '/blog/',
    icon: <StaticImage
      alt=""
      aria-hidden="true"
      src="../images/notepad.png"
      style={iconStyle}
    />,
  }, {
    name: 'Games',
    link: '/games/',
    icon: <StaticImage
      alt=""
      aria-hidden="true"
      src="../images/joystick.png"
      style={iconStyle}
    />,
  }
];

const themeOptions = [{
  value: ThemeSetting.LIGHT, label: 'Light ☀️',
}, {
  value: ThemeSetting.DARK, label: 'Dark 🌙',
}, {
  value: ThemeSetting.SYSTEM, label: 'System 💻'
}];

const getSlug = (location) => {
  if (location.pathname === '/') return '/index/';
  const split = location.pathname.trim().split('/').filter(part => !!part);
  const last = split[split.length - 1];
  return  `/${last}/`;
}

const Header = () => {
  const { changeThemeSetting, themeSetting } = useStyledDarkMode();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  console.log({ themeSetting });
  return (
    <header>
      <Helmet htmlAttributes={{
        lang: 'en',
      }}>
        <meta charSet="utf-8" />
      </Helmet>
      <div style={{ position: 'sticky', zIndex: 9999 }}>
        <AppBar className="nav-bar" as="nav">
          <Toolbar style={{ justifyContent: 'space-between', zIndex: 999 }}>
            <span style={{ maxHeight: '37px'}}>
              <Button className="main-menu-button" onClick={() => setOpen(!open)}>
                <StaticImage
                  alt="a sprouting fly agaric"
                  src="../images/muscarian.png"
                  style={{ marginRight: '1ch' }}
                />
                Muscarian
              </Button>
              {open && (
                <List
                  style={{
                    position: 'absolute',
                    left: '0',
                    minWidth: '16ch',
                    top: '100%'
                  }}
                  onClick={() => setOpen(false)}
                >
                  {menuLinks.map(link => (
                    <ListItem
                      key={link.name}
                      as="li"
                      disabled={location.pathname === `${link.link}/`}
                      style={{
                        minWidth: '12ch',
                        listStyleType: `none`,
                        padding: `1rem`,
                      }}
                    >
                      <Link
                        className="clickable-list-item flex-spread"
                        to={link.link}
                      >
                        {link.icon}
                        {link.name}
                      </Link>
                    </ListItem>

                  ))}
                  {location.pathname !== '/' ? (
                    <>
                      <Divider />
                      <ListItem
                        as="li"
                        style={{
                          minWidth: '12ch',
                          listStyleType: `none`,
                          padding: `1rem`,
                        }}
                      >
                        <Link
                          className="clickable-list-item flex-spread"
                          to="/"
                        >
                          <StaticImage
                            aria-hidden="true"
                            src="../images/computer-earth.png"
                            style={iconStyle}
                          />
                          Index
                        </Link>
                      </ListItem>
                    </>) : null
                  }
                </List>
              )}
              {/* <!--Make this disappear on v. small screen 367px--> */}
              <span className="header-slug">{getSlug(location)}</span>
            </span>
            <span>
              <Select
                defaultValue={themeSetting || ''}
                name="theme select"
                options={themeOptions}
                menuMaxHeight={160}
                tabIndex="0"
                value={themeSetting}
                width={160}
                onChange={(e) => {
                  changeThemeSetting(e.target.value)
                }}
              />
            </span>
          </Toolbar>
        </AppBar>
      </div>
    </header>
  )
}

export default Header