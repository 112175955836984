import React, { useContext } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import { styleReset } from 'react95';

import ms_sans_serif from "react95/dist/fonts/ms_sans_serif.woff2";
import ms_sans_serif_bold from "react95/dist/fonts/ms_sans_serif_bold.woff2";
import '@fontsource/alice';
import { ThemeContext } from "styled-components"

import Header from './header';
import Schema from './schema';
import SEOComponent from './seo';

import { useSetCssVars } from '../hooks/use-set-css-vars';

const GlobalStyles = createGlobalStyle`
  ${styleReset}

  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal;
  }

  body {
    font-family: 'Alice';
  }
`;


const Layout = ({
  articleData,
  children,
  description,
  image,
  subtitle = '',
  type = 'website',
}) => {
  const theme = useContext(ThemeContext)
  useSetCssVars();

  return (
    <main className={`page-container`}>
      <SEOComponent
        articleData={articleData}
        description={description}
        image={image}
        title={`${subtitle} | Muscarian Softworks`}
        type={type}
      />
      <Schema
        description={description}
      />
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <Header />
        <div style={{ paddingTop: '35px'}}>
          {children}
        </div>
      </ThemeProvider>
    </main>
  )
}

export default Layout
